<template>
  <a-upload
    v-model:file-list="fileList"
    action=""
    :customRequest="handleChange"
    list-type="picture-card"
    :maxCount="1"
    @preview="handlePreview"
    @remove="handleDelete"
  >
    <div v-if="fileList.length < 8">
      <plus-outlined />
      <div style="margin-top: 8px">上传贴图</div>
    </div>
  </a-upload>
  <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'

import { uploadFile } from '@/api/upload'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })
}

export default defineComponent({
  name: 'AppImagePicker',

  props: ['value'],

  emits: ['update:value', 'imageChange'],

  components: {
    PlusOutlined
  },

  data() {
    return {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: []
    }
  },

  methods: {
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }

      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },

    async handleChange(e) {
      const url = await uploadFile(e.file)

      this.fileList = [
        {
          url,
          name: e.file.name
        }
      ]

      this.$emit('update:value', url)
      this.$emit('imageChange')
    },

    handleDelete() {
      this.fileList = []
      this.$emit('update:value', '')
      this.$emit('imageChange')
    }
  }
})
</script>

<style lang="scss" scoped></style>
