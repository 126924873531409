import { createRouter, createWebHashHistory } from 'vue-router'

import modelEditor from './modules/modelEditor'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/BuildList'),
    meta: {
      title: '首页'
    }
  },
  ...modelEditor
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
