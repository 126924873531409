<template>
  <div class="image-check">
    <div
      :class="['image-check-box', item.show ? 'active' : '']"
      v-for="(item, index) in checkData"
      :key="index"
      @click="handleChange(index)"
    >
      <img :width="28" :height="28" :src="item.url" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'AppImageCheck',

  emits: ['change'],

  props: {
    value: {
      default: Array
    }
  },

  setup(prop, context) {
    const checkData = reactive(prop.value)
    const handleChange = (index) => {
      const isShown = !checkData[index].show
      checkData[index].show = isShown
      context.emit('change', checkData[index])
    }

    return {
      checkData,
      handleChange
    }
  }
})

</script>

<style lang="scss" scoped>
.image-check-box {
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  padding: 5px;
  border: 1px solid #dbd7d7;
  border-radius: 4px;
  font-size: 0;
  position: relative;
  cursor: pointer;
  &.active {
    border: 1px solid #409eff;
  }
}
</style>
