import { onBeforeUnmount, onMounted } from 'vue'
export * from './eum'
import { keyKinds } from './eum'

let callback = undefined
const keyCodeFunc = (event, callback) => {
  for (const item of keyKinds) {
    if (item.condition(event)) {
      if (callback) callback(item.key)
      event.preventDefault()
      event.returnValue = false
    }
  }
}
export const removeLinstener = () => {
  window.onkeydown = undefined
}
export const addLinstener = () => {
  window.onkeydown = (event) => keyCodeFunc(event, callback)
}

export function useHotkey(cb) {
  if (cb) callback = cb
  onMounted(() => {
    window.onkeydown = (event) => keyCodeFunc(event, callback)
  })
  onBeforeUnmount(() => {
    window.onkeydown = undefined
  })
}
