import AppImageCheck from './app-image-check'
import AppImagePicker from './app-image-picker'
import AppInputNumber from './app-input-number'
import CommonDialog from './common-dialog/index'

export default [
  AppImageCheck,
  AppImagePicker,
  CommonDialog,
  AppInputNumber
]