<template>
  <a-input-number
    v-focus
    class="app-input-number"
    :value="props.value"
    :controls="false"
    :step="step"
    @change="onChange"
    :placeholder="props.placeholder"
  >
    <template #addonBefore>
      <minus-outlined
        :style="{ fontSize: '16px', color: '#fff' }"
        @mousedown="mouseDown(0)"
        @mouseleave="mouseup"
        @mouseup="mouseup"
      />
    </template>
    <template #addonAfter>
      <plus-outlined
        :style="{ fontSize: '16px', color: '#fff' }"
        @mousedown="mouseDown(1)"
        @mouseleave="mouseup"
        @mouseup="mouseup"
      />
    </template>
  </a-input-number>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { removeLinstener, addLinstener } from '@/hook/useHotkey'
export default defineComponent({
  name: 'AppInputNumber',
  directives: {
    focus: {
      mounted() {
        nextTick(() => {
          if (document.querySelectorAll('input')) {
            for (const inputItem of document.querySelectorAll('input')) {
              inputItem.onfocus = () => {
                removeLinstener()
              }
              inputItem.onblur = () => {
                addLinstener()
              }
            }
          }
        })
      },
      beforeUnmount() {
        addLinstener()
      }
    }
  }
})
</script>

<script setup>
import Big from 'big.js'
import { defineProps, defineEmits, ref } from 'vue'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue'
// 鼠标长按
const mouseDown = (clickType) => {
  if (clickType === 0) {
    onMinus()
  } else {
    onPlus()
  }
  timerOut.value = setTimeout(() => {
    if (clickType === 0) {
      timerInterval.value = setInterval(() => {
        onMinus()
      }, 100)
    } else {
      timerInterval.value = setInterval(() => {
        onPlus()
      }, 100)
    }
  }, 1000)
}
// 结束长按
const mouseup = () => {
  if (timerInterval.value) clearInterval(timerInterval.value)
  if (timerOut.value) clearInterval(timerOut.value)
}
const timerInterval = ref(null) // 长按触发的计数器
const timerOut = ref(null) // 长按触发的计数器

const props = defineProps({
  value: Number,

  min: {
    type: Number,
    default: -Infinity
  },
  max: {
    type: Number,
    default: Infinity
  },

  placeholder: String,

  step: {
    type: Number,
    default: 1
  }
})

const emit = defineEmits(['update:value', 'change'])

const onChange = (e) => {
  emit('update:value', e)
  emit('change', e)
}

const onMinus = () => {
  let value = new Big(props.value || 0)
  value = value.minus(props.step)

  let min = props.min

  if (min !== -Infinity) {
    min = new Big(min)
    value = value.gte(min) ? value : min
  }

  onChange(value.toNumber())
}

const onPlus = () => {
  let value = new Big(props.value || 0)
  value = value.plus(props.step)

  let max = props.max

  if (max !== Infinity) {
    max = new Big(max)
    value = value.lte(max) ? value : max
  }

  onChange(value.toNumber())
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixin';

.app-input-number {
  :deep {
    &.ant-input-number-group-wrapper,
    .ant-input-number-group,
    .ant-input-number,
    .ant-input-number-input,
    .ant-input-number-input-wrap {
      height: 28px;
      line-height: 30px;
    }

    .ant-input-number-group {
      border: 1px solid #e0e1e2;
    }

    .ant-input-number {
      width: 68px;
      border: none;
      box-shadow: unset;
    }

    .ant-input-number-input {
      padding: 0 9px;
      font-size: 13px;
      line-height: 28px;
    }

    .ant-input-number-group-addon,
    .ant-input-number-group-addon {
      color: #fff;
      cursor: pointer;
      user-select: none;
      border: 1px solid #e0e1e2;
      @include size(30px);
      line-height: 30px;
      border-radius: 2px;
      padding: 0;
      background-color: #0053c1;

      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }
    }
  }
}
</style>
