import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './router/permission'
import './themes/index.css'
import 'normalize.css/normalize.css'
import commonComponents from '@/common/components'
import 'zkmap'
import ZK from '@gis/zk-base'
import '@gis/zk-base/dist/zk.base.min.css'

ZK.ready(() => {
  global.ZK = ZK
})

const app = createApp(App)

app.use(router).use(Antd).use(createPinia())

commonComponents.forEach((item) => app.component(item.name, item))

app.mount('#app')
