export default [
  {
    path: '/editer',
    name: 'ModelEditer',
    component: () => import('@/views/ModelEditer'),
    redirect: '/editer/modeling',
    children: [
      {
        path: 'modeling',
        name: 'Modeling',
        component: () => import('@/views/ModelEditer/Modeling'),
        meta: {
          title: '三维建模'
        }
      },
      {
        path: 'superimposed',
        name: 'Superimposed',
        component: () => import('@/views/ModelEditer/Superimposed'),
        meta: {
          title: '模型叠加'
        }
      },
      {
        path: 'mark',
        name: 'Mark',
        component: () => import('@/views/ModelEditer/Mark'),
        meta: {
          title: '数据标绘'
        }
      }
    ]
  }
]
