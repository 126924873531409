import eos from '@/plugins/eos'
import { dataURLtoFile } from '@/utils'

/**
 * @desc 上传文件
 * @export uploadFile
 */
export function uploadFile(file, config = {}) {
  return eos.upload(file, config)
}

/**
 * @desc 上传文件
 * @export uploadFile
 */
export function uploadFile2(base64, config = {}) {
  const file = dataURLtoFile(base64)
  return eos.upload(file, config)
}
