const CTRL_S = 'CTRL_S'
const CTRL_A = 'CTRL_A'
const ESC = 'ESC'
const DELETE = 'DELETE'
const CTRL_Z = 'CTRL_Z'
const CTRL_Y = 'CTRL_Y'

export const modelingMethodName = {
  [CTRL_S]: 'handlerSaveProject',
  [ESC]: 'handleDrawEnd',
  [DELETE]: 'handleDelete',
  [CTRL_A]: 'handleImportImage',
  [CTRL_Z]: 'goback',
  [CTRL_Y]: 'restore'
}

export const markMethodname = {
  [ESC]: 'handleDrawEnd',
  [DELETE]: 'handleDelete',
  [CTRL_Z]: 'goback',
  [CTRL_Y]: 'restore'
}

export const keyKinds = [
  {
    condition: (event) => {
      return event.ctrlKey && event.keyCode === 83
    },
    key: CTRL_S
  },
  {
    condition: (event) => {
      return event.ctrlKey && event.keyCode === 65
    },
    key: CTRL_A
  },
  {
    condition: (event) => {
      return event.ctrlKey && event.keyCode === 90
    },
    key: CTRL_Z
  },
  {
    condition: (event) => {
      return event.ctrlKey && event.keyCode === 89
    },
    key: CTRL_Y
  },
  {
    condition: (event) => {
      return event.keyCode === 27
    },
    key: ESC
  },
  {
    condition: (event) => {
      return event.keyCode === 46
    },
    key: DELETE
  }
]
