<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script setup>
import { provide, reactive } from 'vue'
import { Modal } from 'ant-design-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
const locale = reactive(zhCN)

/**
 * 挂载全局通用方法
 */
const confirm = ({ content, title = '提示', okText = '确定', cancelText = '取消', ...conf }) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title,
      content,
      okText,
      cancelText,
      closable: true,
      centered: true,
      icon: '',
      ...conf,
      onOk: () => resolve(),
      onCancel: (res) => {
        if (typeof res === 'function') {
          res()
        }
        reject(res)
      },
      class: 'global-custom-confirm'
    })
  })
}

provide('$confirm', confirm)
</script>

<style lang="scss">
@import '~@/assets/iconfont/iconfont.css';

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}



.global-custom-confirm .ant-modal-content {
  border-radius: 8px;

  .ant-modal-close {
    color: #64686e;
  }

  .ant-modal-body {
    padding: 16px 24px;

    .ant-modal-confirm-body {
      .ant-modal-confirm-title {
        font-weight: bold;
        color: #64686e;
      }

      .ant-modal-confirm-content {
        color: #353a41;
      }
    }

    .ant-modal-confirm-btns {
      float: unset;
      text-align: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;

      .ant-btn {
        border-radius: 4px;
        min-width: 80px;
        background-color: #0053c1;
        color: #fff;

        &:first-child {
          margin-left: 10px;
          background-color: #c5d0df;
        }
      }
    }
  }
}
</style>
