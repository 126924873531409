<template>
  <a-modal
    ref="modalRef"
    v-if="visible && isShowPanel"
    wrapClassName="common-dialog"
    :visible="visible"
    :footer="false"
    :width="width"
    :height="height"
    :maskClosable="maskClosable"
    @cancel="emit('cancel')"
  >
    <template #title>
      <div v-focus ref="modalTitleRef" :class="{ 'model-title': true, 'model-title_drag': drag }">{{ title }}</div>
    </template>

    <slot></slot>

    <div v-if="footer" class="model-footer">
      <a-button type="primary" :loading="loading" @click="emit('confirm')">{{ confirmText }}</a-button>
      <a-button class="btn-cancel" :loading="loading" @click="emit('cancel')">{{ cancelText }}</a-button>
    </div>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode" />
      </div>
    </template>
  </a-modal>
</template>

<script>
import { ref, defineComponent, watch, computed } from 'vue'
import useDrag from './useDrag'
import { removeLinstener, addLinstener } from '@/hook/useHotkey'
export default defineComponent({
  name: 'CommonDialog',
  directives: {
    focus: {
      mounted() {
        removeLinstener()
      },
      beforeUnmount() {
        addLinstener()
      }
    }
  },
  props: {
    visible: Boolean,
    title: String,
    onCancel: Function,
    width: [String, Number],
    height: [String, Number],

    footer: {
      type: Boolean,
      default: true
    },

    // 支持移动
    drag: {
      type: Boolean,
      default: false
    },

    maskClosable: {
      type: Boolean,
      default: false
    },

    // 关闭时销毁 Modal 里的子元素
    destroyOnClose: {
      type: Boolean,
      default: true
    },

    confirmText: {
      type: String,
      default: '确认'
    },

    cancelText: {
      type: String,
      default: '取消'
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // const emit = defineEmits(['confirm', 'cancel'])
    const visibleCopy = computed(() => {
      return props.visible
    })
    const isShowPanel = ref(true)
    watch(visibleCopy, (val) => {
      if (val) isShowPanel.value = true
      if (!val) {
        setTimeout(() => {
          isShowPanel.value = false
        }, 300)
      }
    })
    const modalTitleRef = ref(null)
    const { transformStyle } = useDrag(modalTitleRef, props.drag)
    return {
      isShowPanel,
      transformStyle,
      emit
    }
  }
})
</script>

<style lang="scss">
@import '~@/assets/styles/mixin';

.common-dialog {
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px 2px rgba(83, 95, 106, 0.15);

    .ant-modal-header {
      padding: 11px 24px;
      background-color: #f7f7f8;
    }

    .ant-modal-close-x {
      height: 45px;
      line-height: 45px;
    }
  }

  .model-title {
    color: #64686e;
    user-select: none;

    &_drag {
      cursor: move;
    }
  }

  .model-footer {
    @include flex-center(center);
    margin-top: 20px;

    .ant-btn {
      width: 80px;
      height: auto;
      text-align: center;
      line-height: 1;
      padding: 8px 0;
      border-radius: 4px;

      & + .ant-btn {
        margin-left: 16px;
      }

      &.btn-cancel {
        border: none;
        color: #fff;
        background-color: #c5d0df;
      }
    }
  }
}
</style>
