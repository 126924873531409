export default {
  TITLE: '消防安全开放数据平台',
  SCENE_TYPES: ['2D', '3D'],
  // cookie过期天数
  COOKIE_EXPIRES: 7,
  USEAGE_VIDEO: {
    MODELING:
      'https://front-end-assets.obs.cn-south-1.myhuaweicloud.com/common/import-drawings.mp4?AccessKeyId=S2ZT81W9OZN6XKJXQAEC&Expires=1688894716&Signature=09u4qAAFLZXtaOLVMcpFRkxT79k%3D',
    MARK: 'https://front-end-assets.obs.cn-south-1.myhuaweicloud.com/common/cf-arpa-simulator.mp4?AccessKeyId=S2ZT81W9OZN6XKJXQAEC&Expires=1688893601&Signature=5ZwYm28bhA7q5BvepNGTruQKZGw%3D'
  },
  USERAGE_MANUAL:
    'https://front-end-assets.obs.cn-south-1.myhuaweicloud.com/common/open-3d-OM.pdf?AccessKeyId=S2ZT81W9OZN6XKJXQAEC&Expires=1688895883&Signature=KCnorFsVqE9JxY166Fp5ovkJUTo%3D'
}
